import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import ReactDOM from 'react-dom';
import { Trans } from 'react-i18next';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import {
  modalStyle,
  close,
  next,
  previous,
  pointStyle,
  containerStyle,
  containerImageStyle,
  imageStyle,
  openTab
} from './style';

class GalleryModal extends React.Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = {
      images: {},
      selected: '',
      x: 0,
      y: 0,
      pointModal: false,
      description: '',
      height: 'auto',
      pointSelected: false
    };
  }

  /* create for every image one React reference and points array if don't have one */
  createImagesRefs(images) {
    images.forEach((v, i) => {
      images[i].ref = React.createRef();
      images[i].points = images[i].points ? images[i].points : [];
    });
  }

  componentWillMount() {
    let { images, selected, crudPoints } = this.props;
    this.createImagesRefs(images);
    this.setState(ps => {
      return { ...ps, images, selected, crudPoints };
    });
  }

  /* move from one image to another */
  changeImage(dir = true) {
    let { images, selected } = this.state;
    let image = null;

    images.forEach((i, index) => {
      if (i.id === selected) {
        if (dir) {
          if (index === 0) image = images[images.length - 1];
          else image = images[index - 1];
        } else if (!dir) {
          if (index === images.length - 1) image = images[0];
          else image = images[index + 1];
        }
      }
    });

    if (image && image.id) {
      this.setState(ps => {
        return { ...ps, selected: image.id };
      });
    }
  }

  /* GET mouse position when is moving over the div */
  _onMouseMove(e) {
    let image = this.getSelected();
    const element = ReactDOM.findDOMNode(image.ref.current);
    let percentX = ((e.nativeEvent.offsetX - 10) * 100) / element.clientWidth;
    let percentY = ((e.nativeEvent.offsetY - 14) * 100) / element.clientHeight;

    this.setState(ps => {
      return { ...ps, x: percentX, y: percentY };
    });
  }

  openPointModal() {
    this.setState(ps => {
      return { ...ps, pointModal: true, description: '' };
    });
  }

  closePointModal() {
    this.setState(ps => {
      return {
        ...ps,
        pointModal: false,
        description: '',
        pointSelected: false
      };
    });
  }

  changeDescription(value) {
    this.setState(ps => {
      return { ...ps, description: value ? value : '' };
    });
  }

  /* Open point modal information modal */
  pointInfoModal(event, point) {
    // Avoid display create point modal
    event.preventDefault();
    event.stopPropagation();
    this.setState(ps => ({ ...ps, pointSelected: point }));
  }

  /* Create image point using mouse position */
  addImagePoint() {
    const { x, y, description, images } = this.state;
    let image = this.getSelected();
    let px = parseInt(x);
    let py = parseInt(y);
    // add new point object inside image
    image.points.push({
      x,
      y,
      px,
      py,
      description,
      complete: false,
      id: parseInt(1000 + Math.random() * (10000 - 1000))
    });
    // update image object inside images array
    images.forEach((i, index) => {
      if (i.id === image.id) images[index] = image;
    });

    this.setState(ps => ({
      ...ps,
      images,
      pointModal: false,
      description: ''
    }));
  }

  /* Delete point from image points array */
  deleteImagePoint() {
    let { images, pointSelected } = this.state;
    let image = this.getSelected();
    // delete point from image points list
    image.points = image.points.filter(p => p.id !== pointSelected.id);
    // update image object inside images array
    images.forEach((i, index) => {
      if (i.id === image.id) images[index] = image;
    });
    this.setState(ps => ({ ...ps, images, pointSelected: false }));
  }

  completeImagePoint() {
    let { images, pointSelected } = this.state;
    let image = this.getSelected();
    // update point from image points list
    let index = image.points.findIndex(p => p.id === pointSelected.id);
    image.points[index].complete = true;
    // update image object inside images array
    images.forEach((i, index) => {
      if (i.id === image.id) images[index] = image;
    });
    this.setState(ps => ({ ...ps, images, pointSelected: false }));
  }

  /* Create image points html */
  buildImagePoints(image) {
    let points = [];

    image.points.forEach(point => {
      points.push(
        <div
          key={'point-' + point.id}
          id={'point-' + point.id}
          className={'image-point'}
          style={{
            ...pointStyle,
            top: point.y + '%',
            left: point.x + '%',
            color: point.complete ? 'blue' : 'yellow'
          }}
          onClick={e => {
            this.pointInfoModal(e, point);
          }}
        >
          <i className="fa fa-2x fa-circle shadow-lg" />
          <UncontrolledTooltip
            target={'point-' + point.id}
            key={`point-${point.py}-${point.px}`}
            placement="left"
            delay={0}
          >
            {point.description}
          </UncontrolledTooltip>
        </div>
      );
    });

    return points;
  }

  getSelected() {
    let { images, selected } = this.state;
    return images.find(i => i.id === selected);
  }

  /* Calculate & Update state of new dimension */
  updateDimensions() {
    const element = ReactDOM.findDOMNode(this.container.current);
    this.setState(ps => {
      return { ...ps, height: element.clientHeight };
    });
  }

  /* Add event listener to change image max-height */
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  /* Remove event listener */
  componentWillUnmount() {
    //window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  render() {
    let {
      pointModal,
      description,
      height,
      pointSelected,
      crudPoints,
      images
    } = this.state;
    let image = this.getSelected();

    return createPortal(
      <div className="" style={{ ...modalStyle }}>
        <button
          className=""
          style={{ ...close }}
          onClick={() => this.props.closeModal()}
        >
          <span>&#10005;</span>
        </button>

        <button
          className=""
          style={{ ...openTab }}
          onClick={() => this.props.openNewTab()}
        >
          <span>&#9715;</span>
        </button>

        {images.length > 1 ? (
          <button
            className=""
            style={{ ...previous }}
            onClick={() => this.changeImage()}
          >
            <span>&#8249;</span>
          </button>
        ) : null}

        {images.length > 1 ? (
          <button
            className=""
            style={{ ...next }}
            onClick={() => this.changeImage(false)}
          >
            <span>&#8250;</span>
          </button>
        ) : null}

        <div className="" ref={this.container} style={{ ...containerStyle }}>
          <div
            style={{ ...containerImageStyle }}
            ref={image.ref}
            onMouseMove={this._onMouseMove.bind(this)}
            onClick={() => this.openPointModal()}
          >
            {this.buildImagePoints(image)}
            <img
              src={image.src}
              alt={image.name}
              style={{ ...imageStyle, maxHeight: height }}
            />
          </div>
        </div>

        <Modal
          isOpen={pointModal && crudPoints}
          toggle={() => this.closePointModal()}
        >
          <ModalHeader toggle={() => this.closePointModal()}>
            <Trans>Create Image Point</Trans>
          </ModalHeader>
          <ModalBody>
            <FormGroup className={'has-label'}>
              <Label>
                <Trans>Description</Trans>
              </Label>
              <Input
                bsSize="lg"
                type="textarea"
                placeholder="..."
                value={description || ''}
                onChange={event => this.changeDescription(event.target.value)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              className="float-right"
              onClick={() => this.closePointModal()}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              className="float-right"
              color="success"
              onClick={() => this.addImagePoint()}
            >
              <Trans>Save</Trans>
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={pointSelected.id ? true : false}
          toggle={() => this.closePointModal()}
        >
          <ModalHeader toggle={() => this.closePointModal()}>
            <Trans>Image Point</Trans>
          </ModalHeader>
          <ModalBody>
            {pointSelected.description}
            <hr />
          </ModalBody>
          <ModalFooter>
            <Button
              className="float-right"
              onClick={() => this.closePointModal()}
            >
              <Trans>Cancel</Trans>
            </Button>
            {crudPoints ? (
              <Button
                className="float-right"
                color="info"
                onClick={() => this.completeImagePoint()}
              >
                <Trans>Complete</Trans>
              </Button>
            ) : null}
            {crudPoints ? (
              <Button
                className="float-right"
                color="danger"
                onClick={() => this.deleteImagePoint()}
              >
                <Trans>Delete</Trans>
              </Button>
            ) : null}
          </ModalFooter>
        </Modal>
      </div>,
      document.getElementById('root')
    );
  }
}

GalleryModal.defaultProps = {
  images: [],
  selected: false,
  crudPoints: true
};

GalleryModal.propTypes = {
  images: PropTypes.array,
  selected: PropTypes.string,
  crudPoints: PropTypes.bool
};

export default GalleryModal;
