import React, { Component } from 'react';
import { Spinner } from '../../components';

export default class extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    let { photo } = this.props;

    let img = new Image();
    img.addEventListener('load', () => {
      this.setState({
        loading: false
      });
    });

    img.src = photo.src;
  }

  render() {
    let { loading } = this.state,
      { photo, ...rest } = this.props;

    let props = {
      [`${loading ? 'data-src' : 'src'}`]: photo.src,
      ...rest
    };

    if (loading) {
      props.className = 'image-placeholder fade in';
    }

    return (
      <div
        className="thumbnail"
        style={{ margin: props.margin, position: 'relative' }}
      >
        {loading ? <Spinner /> : null}
        <img ref={node => (this.img = node)} {...photo} alt="" />
      </div>
    );
  }
}
