import React from 'react';
import Script from 'react-load-script';

export default class GoogleMapLibrary extends React.Component {
  state = { scriptLoaded: false };

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          height: 'auto',
          position: 'relative',
          display: 'inline-block'
        }}
      >
        <Script
          key={'google-map-library'}
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBmLqBfl1iiPFKL2lNFvbeb-tz4jzZB75Q&libraries=places"
          onLoad={this.handleScriptLoad.bind(this)}
        />
        {this.state.scriptLoaded ? this.props.children : null}
      </div>
    );
  }
}
