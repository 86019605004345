import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { imageSelectButton, valide, basicStyle, points } from './style';
import { Trans } from 'react-i18next';

class GalleryImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: {},
      classes: '',
      selected: false
    };
  }

  componentWillReceiveProps({ image, classes, selected }) {
    this.setState(ps => {
      return { ...ps, image, classes, selected };
    });
  }

  componentWillMount() {
    let { image, classes, selected } = this.props;
    this.setState(ps => {
      return { ...ps, image, classes, selected };
    });
  }

  clickSelect(event) {
    // to avoid display modal with images
    event.preventDefault();
    event.stopPropagation();
    this.props.clickSelect();
  }

  render() {
    let { image, selected } = this.state;
    let { icon, iconSelect } = this.props;
    let url = image.src;

    return (
      <div
        id={'image_' + image.id.slice(-5)}
        className={
          selected
            ? 'rounded rounded-top-left p-1 box-shadow'
            : 'rounded p-1 box-shadow'
        }
        style={{
          ...basicStyle,
          backgroundImage: `url(${url})`
        }}
        onClick={() => this.props.clickHover()}
      >
        <UncontrolledTooltip
          placement="top"
          target={'image_' + image.id.slice(-5)}
        >
          {image.name}
        </UncontrolledTooltip>

        <div
          className={selected ? '' : ''}
          style={{ ...imageSelectButton }}
          onClick={e => this.clickSelect(e)}
        >
          {selected ? icon : iconSelect}
        </div>

        <div style={{ ...valide }}>
          {image.valide ? <Trans>Valid</Trans> : ''}
        </div>

        <div style={{ ...points }}>
          {image.points && image.points.length ? (
            <i className="fa fa-star fa-2x text-warning" />
          ) : (
            ''
          )}
        </div>

        <div style={{ ...valide, left: '20%' }}>
          {image.piece ? <Trans>{image.piece}</Trans> : ''}
        </div>
      </div>
    );
  }
}

GalleryImage.defaultProps = {
  image: {},
  classes: '',
  selected: false,
  icon: null,
  iconSelect: null
};

GalleryImage.propTypes = {
  image: PropTypes.object,
  classes: PropTypes.string,
  selected: PropTypes.bool,
  icon: PropTypes.object,
  iconSelect: PropTypes.object
};

export default GalleryImage;
