import React, { Component } from 'react';
import styles from './styles';

const colors = [
  { name: 'black', rgb: [0, 0, 0] },
  { name: 'silver', rgb: [192, 192, 192] },
  { name: 'white', rgb: [255, 255, 255] },
  { name: 'red', rgb: [255, 0, 0] },
  { name: 'blue', rgb: [0, 0, 255] },
  { name: 'cyan', rgb: [0, 255, 255] },
  { name: 'green', rgb: [0, 128, 0] },
  { name: 'yellow', rgb: [255, 255, 0] }
];

class Point extends Component {
  constructor(props) {
    super(props);
    this.state = {
      point: null
    };
  }

  componentDidMount() {
    this.setState({ point: this.props.point });
  }

  updateText = data => {
    this.setState(ps => ({
      ...ps,
      point: { ...ps.point, text: data }
    }));
  };

  savePoint = () => {
    let { point } = this.state;
    this.setState({ point: null }, () => this.props.savePoint(point));
  };

  render() {
    let { savePointButton, closePointButton, closeModalPoint } = this.props;
    let { point } = this.state;
    // by default the modal is diplayed on the center of the screen
    let top = 125;
    let left = 'calc(50% - 125px)';

    if (point && window.innerWidth > 768) {
      top = point.top;
      left = point.left;
    }

    return (
      <div>
        {point ? (
          <div
            style={{
              ...styles.create_text,
              top,
              left
            }}
          >
            <textarea
              autoFocus
              style={{ ...styles.create_text_area }}
              value={point.text}
              onChange={e => this.updateText(e.target.value)}
            />
            <ul style={{ ...styles.colors_list }}>
              {colors.map(c => (
                <li
                  key={'image-point-color-' + c.name}
                  style={{ ...styles.colors_list_item }}
                >
                  <div
                    style={{
                      ...styles.color_select,
                      background: `${c.name}`,
                      width: c.name === point.fill ? '30px' : '20px',
                      height: c.name === point.fill ? '30px' : '20px'
                    }}
                    onClick={() =>
                      this.setState(ps => ({
                        ...ps,
                        point: { ...ps.point, fill: c.name }
                      }))
                    }
                  />
                </li>
              ))}
            </ul>
            <div style={{ textAlign: 'center' }}>
              <button
                style={{ ...styles.buttons, ...savePointButton.styles }}
                onClick={() => this.savePoint()}
              >
                {savePointButton.icon ? savePointButton.icon : 'Save'}
              </button>

              <button
                style={{ ...styles.buttons, ...closePointButton.styles }}
                onClick={closeModalPoint}
              >
                {closePointButton.icon ? closePointButton.icon : 'Close'}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Point;
