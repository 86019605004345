import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { createPortal } from 'react-dom';
import Images from './images';

class GalleryCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      resizeImage: false
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    let { images, selected, crudPoints } = this.props;
    this.setState(ps => ({ ...ps, images, selected, crudPoints }));
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      resizeImage: false
    });
  }

  getSelected() {
    let { images, selected } = this.state;
    return images.find(i => i.id === selected);
  }

  /* move from one image to another */
  changeImage(dir = true) {
    let { images, selected } = this.state;
    let image = null;

    images.forEach((i, index) => {
      if (i.id === selected) {
        if (dir) {
          if (index === 0) image = images[images.length - 1];
          else image = images[index - 1];
        } else if (!dir) {
          if (index === images.length - 1) image = images[0];
          else image = images[index + 1];
        }
      }
    });

    if (image && image.id) {
      this.setState(ps => {
        return { ...ps, selected: image.id };
      });
    }
  }

  resizeImage() {
    this.setState({ resizeImage: true });
  }

  render() {
    let { next, prev, closeModal, resize, close } = this.props;
    let image = this.getSelected();

    return createPortal(
      <div
        id={'gallery-canvas'}
        style={{
          ...styles.modal,
          width: this.state.width,
          height: this.state.height
        }}
      >
        <div style={{ ...styles.header }}>
          <div style={{ ...styles.buttonsContainer }}>
            <button
              onClick={() => this.resizeImage()}
              style={{ ...styles.resize, ...resize.styles }}
            >
              {resize.icon ? resize.icon : 'Resize'}
            </button>

            <button
              onClick={() => this.changeImage(true)}
              style={{ ...styles.prev, ...prev.styles }}
            >
              {prev.icon ? prev.icon : 'Prev'}
            </button>

            <button
              onClick={() => this.changeImage(false)}
              style={{ ...styles.next, ...next.styles }}
            >
              {next.icon ? next.icon : 'Next'}
            </button>

            <button
              onClick={() => closeModal()}
              style={{ ...styles.close, ...close.styles }}
            >
              {close.icon ? close.icon : 'Close'}
            </button>
          </div>
          <p style={{ float: 'left' }}>
            {image && image.name ? image.name : 'Image'}
          </p>
        </div>

        {image && image.src ? (
          <Images
            elements={image.elements || []}
            imageUrl={image.src}
            x
            icons={[]}
            savePointButton={this.props.savePointButton}
            closePointButton={this.props.closePointButton}
            resize={this.state.resizeImage}
            height={this.state.height - 10}
            savePoints={data =>
              this.props.savePoints(data, this.state.selected)
            }
          />
        ) : null}
      </div>,
      document.getElementById('root')
    );
  }
}

GalleryCanvas.defaultProps = {
  images: [],
  selected: false,
  crudPoints: true
};

GalleryCanvas.propTypes = {
  images: PropTypes.array,
  selected: PropTypes.string,
  crudPoints: PropTypes.bool
};

export default GalleryCanvas;
