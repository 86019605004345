export const modalStyle = {
  background: 'rgba(0, 0, 0, 0.7)',
  position: 'absolute',
  height: '100%',
  zIndex: '1040',
  top: '0',
  left: '0',
  width: '100%',
  padding: '20px 20px 30px 20px',
  textAlign: 'center'
};

export const buttons = {
  fontSize: '30px',
  color: 'white',
  fontWeight: 'bolder',
  cursor: 'pointer',
  border: 'none',
  background: 'none',
  lineHeight: 'normal',
  zIndex: 999
};

export const close = {
  position: 'absolute',
  right: '15px',
  ...buttons
};

export const next = {
  ...buttons,
  right: '0',
  top: 'calc(50% - 30px)',
  position: 'absolute',
  fontSize: '70px'
};

export const previous = {
  ...buttons,
  left: '0',
  top: 'calc(50% - 30px)',
  position: 'absolute',
  fontSize: '70px'
};

export const pointStyle = {
  position: 'absolute',
  color: 'yellow',
  zIndex: 1,
  textShadow: '3px 5px 2px #474747'
};

export const containerStyle = {
  height: '100%',
  width: '90%',
  textAlign: 'center',
  position: 'relative',
  display: 'inline-block'
};

export const containerImageStyle = {
  position: 'relative',
  maxHeight: '100%',
  maxWidth: '100%',
  display: 'inline-block',
  top: '50%',
  transform: 'translateY(-50%)'
};

export const imageStyle = {
  height: 'auto',
  maxHeight: '100%',
  width: 'auto',
  maxWidth: '100%',
  position: 'relative',
  cursor: 'pointer'
};

export const valide = {
  color: 'yellow',
  fontSize: '14px',
  fontWeight: 'bold',
  position: 'absolute',
  top: '86%',
  left: '2%',
  width: 'fit-content',
  background: 'rgba(0,0,0,0.5)',
  padding: '0 5px',
  borderRadius: '10%'
};

export const imageSelectButton = {
  width: 'fit-content'
};

export const basicStyle = {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100%',
  height: '200px',
  backgroundColor: 'gray',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden'
};

export const points = {
  position: 'absolute',
  right: '5px',
  top: '5px'
};

export const openTab = {
  position: 'absolute',
  ...buttons,
  right: '60px',
  top: '10px',
  fontSize: '40px'
};

export default {
  modalStyle,
  buttons,
  close,
  next,
  previous,
  pointStyle,
  containerStyle,
  containerImageStyle,
  imageStyle,
  valide,
  basicStyle,
  points
};
